<template>
  <div>
    <button class="centerBtn" @click="addEdition = true">添加版本</button>
    <div class="mt20">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchObj.productType"
            placeholder="请输入产品"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn ml20">搜索</button>
        </el-form-item>
        <el-form-item>
          <button class="centerBtn resetbtn">重置</button>
        </el-form-item>
      </el-form>

      <Table
        :data1="tableData"
        :columns1="columns"
        :total="total"
        :loading="loading"
				@getAjax="getTableData"
			/>
			
      <!-- 添加版本弹框 -->
      <el-drawer
        :title="isAdd ? '添加版本' : '编辑'"
        :visible.sync="addEdition"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
        :before-close="handleCancelEdit"
      >
        <div class="demo-drawer__content">
          <el-form :model="form" :rules="rules">
            <el-form-item
              label="产品"
              :label-width="formLabelWidth"
              prop="productType"
            >
              <el-select
                v-model="form.productType"
                placeholder="请选择"
                class="centerCzSelInput"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="平台"
              :label-width="formLabelWidth"
              prop="platformType"
            >
              <el-select
                v-model="form.platformType"
                placeholder="请选择"
                class="centerCzSelInput"
              >
                <el-option
                  v-for="item in platformList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="版本编号"
              :label-width="formLabelWidth"
              prop="versionNo"
            >
              <el-input
                v-model="form.versionNo"
                autocomplete="off"
                class="centerCzInput"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="版本名称"
              :label-width="formLabelWidth"
              prop="versionName"
            >
              <el-input
                v-model="form.versionName"
                autocomplete="off"
                class="centerCzInput"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>

            <el-form-item
              label="更新描述"
              :label-width="formLabelWidth"
              prop="updateDescribe"
            >
              <el-input
                type="textarea"
                v-model="form.updateDescribe"
                autocomplete="off"
                class="centerCzInput"
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>

            <!-- <el-form-item label="文件包" :label-width="formLabelWidth" prop="package">
                            <el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/"
                                multiple>
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">* 安卓系统的文件后缀为.apk，IOS系统的文件后缀为.ipa</div>
                            </el-upload>
                        </el-form-item> -->

            <el-form-item label="更新方式">
              <el-radio-group v-model="form.updateType">
                <el-radio :label="zero">非强制更新</el-radio>
                <el-radio :label="one">强制更新</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="更新时间">
              <el-radio-group v-model="form.updateTimeType">
                <el-radio :label="one">定时更新</el-radio>
                <el-radio :label="two">立即更新</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="定时更新时间" :label-width="formLabelWidth">
              <el-time-picker
                v-model="form.updateTime"
                placeholder="选择时间"
                value-format="HH:mm:ss"
              >
              </el-time-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="demo-drawer__footer">
          <el-button
            class="centerCzbtn mr20"
            @click="getSelect"
            :loading="isLoading"
            >立即创建</el-button
          >
          <button class="centerBtn resetbtn" @click="handleCancelEdit">
            取消
          </button>
        </div>
      </el-drawer>
			
      <el-dialog title="删除" :visible.sync="delDia" width="30%">
        <span>确认删除吗</span>
        <span slot="footer" class="dialog-footer">
          <button class="centerBtn" @click="getdel">确 定</button>
          <button class="centerBtn resetbtn ml20" @click="handleCancelDel">
            取 消
          </button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
//1强制更新 0非强制更新
//1定时更新 2立即更新
import { _FindAll, _FindAdd, _FindDit, _FindDel } from "@/api/mobileManagement";
export default {
  components: {
    Table,
  },
  data() {
    return {
			total: 0,
      loading: false,
      isLoading: false,
      addEdition: false,
      isAdd: true,
			delDia: false,
      formLabelWidth: "100px",
      platform: "",
      versionManagementId: "",
      zero: 0,
      one: 1,
      two: 2,
      form: {
        productType: "",
        platformType: "",
        versionNo: "",
        versionName: "",
        updateDescribe: "",
        updateType: "",
        updateTimeType: "",
        updateTime: "",
        id: "",
      },
      rules: {
        productType: [
          {
            required: true,
            message: "请选择产品",
            trigger: "change",
          },
        ],
        platformType: [
          {
            required: true,
            message: "请选择版本",
            trigger: "change",
          },
        ],
        versionNo: [
          {
            required: true,
            message: "请输入版本编号",
            trigger: "blur",
          },
        ],
        versionName: [
          {
            required: true,
            message: "请输入版本名称",
            trigger: "blur",
          },
        ],
        updateDescribe: [
          {
            required: true,
            message: "请输入更新描述",
            trigger: "blur",
          },
        ],
        // package: [
        //   {
        //     required: true,
        //     message: "请上传文件包",
        //     trigger: "upload",
        //   },
        // ],
      },
      productList: [
        {
          value: 1,
          label: "APP",
        },
        {
          value: 2,
          label: "PAD",
        },
      ],
      platformList: [
        {
          value: 1,
          label: "android",
        },
        {
          value: 2,
          label: "ios",
        },
      ],
      searchObj: {
        page: 1,
        size: 10,
        productType: "",
      },
      tableData: [
        {
          product: "",
          platform: "",
          edition: "",
          forcedUpdate: "",
          updateDescription: "",
          downloadAddress: "",
          creator: "",
          releaseTime: "",
        },
      ],
      columns: [
        {
          label: "产品",
          prop: "product",
          render: (h, params) => {
            if (params.row.productType == 1) {
              return h("span", "APP");
            } else {
              return h("span", "PAD");
            }
          },
        },
        {
          label: "平台",
          render: (h, params) => {
            if (params.row.platformType == 1) {
              return h("span", "android");
            } else {
              return h("span", "ios");
            }
          },
        },
        {
          label: "版本",
          prop: "versionNo",
        },
        {
          label: "强制更新",
          //   prop: "forcedUpdate",
          render: (h, params) => {
            if (params.row.updateType == 0) {
              return h("span", "否");
            } else {
              return h("span", "是");
            }
          },
        },
        {
          label: "更新描述",
          prop: "updateDescribe",
        },
        // {
        //   label: "下载地址",
        //   prop: "downloadAddress",
        // },
        {
          label: "创建人",
          prop: "userName",
        },
        {
          label: "发布时间",
          prop: "createTime",
        },
        {
          label: "操作",
          // fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA ",
                  on: {
                    click: () => {
                      this.isAdd = false;
                      this.addEdition = true;
                      this.form = {
                        productType: params.row.productType,
                        platformType: params.row.platformType,
                        versionNo: params.row.versionNo,
                        versionName: params.row.versionName,
                        updateDescribe: params.row.updateDescribe,
                        updateType: params.row.updateType,
                        updateTimeType: params.row.updateTimeType,
                        updateTime: params.row.updateTime,
                        id: params.row.id,
                      };
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.delDia = true;
                      //   this.rowData = params.row;
                      this.versionManagementId = params.row.id;
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getSelect() {
      if (this.isAdd == true) {
        this.getAdd();
      } else {
        this.getdit();
      }
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      let { success, message, data } = await _FindAll(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },
	
		handleCancelEdit(){
			this.addEdition = false;
		},

    async getAdd() {
      this.isLoading = true;
      _FindAdd(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success == true) {
          this.$message.success("添加成功");
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
        this.addEdition = false;
        this.getTableData();
      });
    },
    async getdit() {
      this.isLoading = true;
      _FindDit(this.form).then((res) => {
        if (!res) {
          this.isLoading = false;
        }
        if (res.success == true) {
          this.$message.success("编辑成功");
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
        this.addEdition = false;
        this.getTableData();
      });
    },
	
		handleCancelDel(){
			this.delDia = false;
		},
		
    async getdel() {
      let { success, message, data } = await _FindDel({
        versionManagementId: this.versionManagementId,
      });
			
      if (success == true) {
        this.$message.success("删除成功");
      }
			
      this.delDia = false;
      this.getTableData();
    },
  },
};
</script>

<style>
.el-upload-dragger {
  width: 300px;
  height: 180px;
}
</style>
