import service from '@/until/api'
// 查询所有菜单
export function _FindAll(data) {
    return service({
        url: '/system-nacos/versionManagement/findAllPage',
        method: 'GET',
        params: data
    })
}

export function _FindAdd(data) {
    return service({
        url: '/system-nacos/versionManagement/addVersionManagement',
        method: 'POST',
        data
    })
}
export function _FindDit(data) {
    return service({
        url: '/system-nacos/versionManagement/updateVersionManagement',
        method: 'POST',
        data
    })
}

export function _FindDel(data) {
    return service({
        url: '/system-nacos/versionManagement/deleteVersionManagement',
        method: 'GET',
        params: data
    })
}